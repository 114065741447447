import React, { Component } from "react";
import { withTranslation  } from "react-i18next";

import { Parallax } from "react-parallax";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  title: {
    marginTop: theme.spacing(10),
    padding: theme.spacing(2),
    textAlign: "center",
    display: "block",
    color: "white",
    textShadow: "1px 1px 1px black"
  },
  subtitle: {
    padding: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    textAlign: "center",
    display: "block",
    color: "white",
    textShadow: "1px 1px 1px black"
  },
});

class RentInfo extends Component {

  render() {
    const { classes } = this.props;
    const { t }  = this.props;
    return (
      <div id="info">
        <Parallax bgImage={require("../assets/img/bg3.jpg")} strength={300}>
          <Typography className={classes.title} variant="h3" noWrap>
          {t("rent.title")}
          </Typography>
          <Typography className={classes.subtitle} variant="h6">
          {t("rent.subtitle")}
            
          </Typography>
          <div style={{ height: "200px" }}></div>
        </Parallax>
      </div>
    );
  }
}

RentInfo.propTypes = {
  classes: PropTypes.object.isRequired
};
RentInfo = withStyles(styles)(RentInfo)
export default withTranslation()(RentInfo);
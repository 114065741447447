import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.css";

import "./i18n";

// import { createStore } from 'redux'
// import rootReducer from './reducer'
// const store = createStore(rootReducer)

// store
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import rootReducer from './reducer'

const store = createStore(rootReducer)


ReactDOM.render(
  <Suspense fallback={<div>Loading</div>}>
    <Provider store={store}>
      <App />
    </Provider>
  </Suspense>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

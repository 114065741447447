

const caravanDataReducer = (state = [], action) => {
    switch (action.type) {
        case 'SET_CARAVAN_DATA':
            console.log("reducer caravanDataReducer", action)
            return state = action.payload;
        default:
            return state;
    }
}

export default caravanDataReducer;
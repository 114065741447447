import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Toolbar from "@material-ui/core/Toolbar";

import Login from "../components/login";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
}));

export default function RentFooter(props) {
  const classes = useStyles();

  return (
    <div>
      <Toolbar>
        <div className={classes.grow}></div>
        <Login />
      </Toolbar>
    </div>
  );
}

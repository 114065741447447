import React, { Component } from "react";

import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "moment/locale/et";

import { et } from "date-fns/locale";

class CalendarBook extends Component {
  render() {
    return (
      <Calendar
        locale={et}
        date={new Date()}
        disabledDates={this.props.disabledDates.map((d) => new Date(d))}
      />
    );
  }
}

export default CalendarBook;

import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import emailjs from "emailjs-com";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";

const styles = theme => ({
  button: {
    margin: theme.spacing(1)
  },
  root: {
    maxWidth: 345
  },
  avatar: {
    backgroundColor: "red"
  }
});

class EmailUs extends Component {

  sendEmail = event => {
    event.preventDefault();
    emailjs
      .sendForm(
        "gmail",
        "userquestion",
        event.target,
        "Pa2eQU4XliNHuBQDV"
      )
      .then(
        result => {
          console.log(result.text);
        },
        error => {
          alert("Something went wrong, please contact us directly via email in contact section")
          console.log(error.text);
        }
      );
    this.closeFrom();
  };

  closeFrom = () => {
    this.props.closeForm();
  };

  render() {
    const { t } = this.props;
    const { classes } = this.props;
    return (
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              S
            </Avatar>
          }
          title={t("email.title")}
          subheader={t("email.subtitle")}
        />
        <form onSubmit={this.sendEmail}>
          <CardContent>
            <input type="hidden" name="contact_number" />
            <TextField
              fullWidth
              label={t("email.telephoneNr")}
              inputProps={{ type: "tel", name: "senderTelephoneNr" }}
            />
            <TextField
              fullWidth
              label={t("email.email")}
              inputProps={{ type: "email", name: "senderEmail" }}
            />

            <TextField
              fullWidth
              label={t("email.message")}
              multiline
              rows="4"
              placeholder={t("email.messagePlaceholder")}
              inputProps={{ name: "message" }}
            />
          </CardContent>

          <CardActions disableSpacing>
            <Button
              type="submit"
              value="Send"
              variant="contained"
              color="primary"
              className={classes.button}
              endIcon={<SendIcon>send</SendIcon>}
            >
              {t("email.submit")}
            </Button>
          </CardActions>
        </form>
      </Card>
    );
  }
}

EmailUs.propTypes = {
  classes: PropTypes.object.isRequired
};
EmailUs = withStyles(styles)(EmailUs);
export default withTranslation()(EmailUs);

import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import Fab from "@material-ui/core/Fab";
import MenuItem from "@material-ui/core/MenuItem";
import SideMenu from './sideMenu';

import i18next from 'i18next';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  list: {
    width: 250
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    },
    textShadow: "1px 1px 2px black"
  },
  langFab: {
    backgroundColor: "transparent",
    boxShadow: theme.shadows[12],
    color: "white",
    fontWeight: "bold",
    textShadow: "1px 1px 2px black"
  },
  menuFab: {
    backgroundColor: "transparent",
    boxShadow: theme.shadows[12],
    marginRight: 20,
    color: "white",
    fontWeight: "bold",
    textShadow: "1px 1px 2px black",
    fontSize: "1.7rem"
  }
}));

function Header() {
  const { t } = useTranslation();

  const locales  = i18next.options.whitelist.slice(0,3)

  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
    right: false
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleClickListItem = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    i18next.changeLanguage(locales[index]);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (side, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

    return (
    <AppBar style={{ background: "transparent", boxShadow: "none" }}>
      <Toolbar>
        <Fab
          className={classes.menuFab}
          onClick={toggleDrawer("left", true)}
          aria-label="open drawer"
          edge="start"
        >
          ‏‏‎≡‏‏‎
        </Fab>
        <Typography className={classes.title} variant="h6" noWrap>
          {t("header.name")}
        </Typography>
        <div>
          <Fab className={classes.langFab} onClick={handleClickListItem}>
            {i18next.language}
          </Fab>
          <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {locales.map((option, index) => (
              <MenuItem
                key={option}
                selected={index === selectedIndex}
                onClick={event => handleMenuItemClick(event, index)}
              >
              {option.toUpperCase()}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </Toolbar>
      <SideMenu side={"left"} state={state} toggleDrawer={toggleDrawer}/>
    </AppBar>
  );
}

export default Header;

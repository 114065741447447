import React, { Component } from "react";

import Modal from "@material-ui/core/Modal";

import EmailUs from "./email";

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = { isModalOpen: false };
  }

  handleOpen() {
    this.setState({ isModalOpen: true });
  }
  handleClose=()=> {
    this.setState({ isModalOpen: false });
  }

  render() {
    return (
      <Modal
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.isModalOpen}
        onClose={()=>this.setState({ isModalOpen: false })}
      >
        <div>
          <EmailUs closeForm={this.handleClose}/>
        </div>
      </Modal>
    );
  }
}

export default ContactUs;

import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const styles = theme => ({
  modal: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  iconButton: {
    position: "absolute",
    top: "5px",
    right: "5px",
    color: "white"
  },
  icon: {
    fontSize: 50
  },
  modalImg: {
    width: "100vh"
  }
});

class ImageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      imageSrc: ""
    };
  }

  toggle(imageSrc) {
    this.setState({ imageSrc: imageSrc });
    this.setState({ isModalOpen: !this.state.isModalOpen });
  }

  handleClose = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <Modal
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.isModalOpen}
        onClose={this.handleClose}
      >
        <div>
          {this.state.imageSrc ? (
            <img
              className={classes.modalImg}
              src={require(`../assets/img/catering1/${this.state.imageSrc}`)}
              alt={this.state.imageSrc}
            />
          ) : null}
          <IconButton className={classes.iconButton} onClick={this.handleClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>
        </div>
      </Modal>
    );
  }
}

ImageModal.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(ImageModal);

import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import { HashLink as Link } from "react-router-hash-link";

import RestaurantOutlinedIcon from "@material-ui/icons/RestaurantOutlined";
import PhotoLibraryOutlinedIcon from "@material-ui/icons/PhotoLibraryOutlined";
import RestaurantMenuOutlinedIcon from "@material-ui/icons/RestaurantMenuOutlined";

import Footer from "./footer";
import ImagesGrid from "./imagesGrid";
import ContactUs from "./contactUs";

const styles = (theme) => ({
  homeImg: {
    display: "flex",
    maxHeight: 1000,
  },

  background: {
    background: "whitesmoke",
  },

  title: {
    marginTop: theme.spacing(10),
    textAlign: "center",
    display: "block",
    color: "#333",
    // textShadow: "1px 1px 1px black",
  },
  subtitle: {
    padding: theme.spacing(2),
    textAlign: "center",
    display: "block",
    color: "#333333",
    // textShadow: "1px 1px 1px black",
  },
  grid: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  gridItem: {
    textAlign: "center",
  },
  button: {
    // color: "white",
    // textShadow: "1px 1px 1px black",
  },
  icon: {
    fontSize: 70,
    color: "darkgrey",
    paddingBottom: theme.spacing(2),
  },
  fbPage: {
    border: "none",
    overflow: "hidden",
    zIndex: 999,
  },
  priaImg: {
    minHeight: 35,
    maxWidth: "100%",
  },
  priaText: {
    padding: theme.spacing(1),
    textAlign: "center",
    display: "block",
    color: "#333333",
    maxWidth: 600,
  },
  euImg: {
    maxHeight: 200,
    maxWidth: "100%",
  },
});

class HomeCatering extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
  }

  render() {
    const { t } = this.props;
    const { classes } = this.props;
    return (
      <div id="catering" className={classes.background}>
        <Grid container direction="row" justify="center">
          <Grid item xs={12} sm={6}>
            {/* <Typography className={classes.title} variant="h3">
                {t("landing.catering.title")}
              </Typography> */}
            <Typography className={classes.subtitle} variant="h4">
              {t("landing.catering.subtitle")}
            </Typography>
            <Grid container justify="center" spacing={2}>
              <Grid className={classes.gridItem} item lg={6} md={5} sm={4}>
                <RestaurantOutlinedIcon className={classes.icon} />
                <Typography className={classes.subtitle} variant="h6" paragraph>
                  {t("landing.catering.info")}
                </Typography>
                <Link to="/catering#info" smooth>
                  <Button type="button" className={classes.button}>
                    {t("landing.readMore")}
                  </Button>
                </Link>
              </Grid>
              <Grid className={classes.gridItem} item lg={6} md={5} sm={4}>
                <PhotoLibraryOutlinedIcon className={classes.icon} />
                <Typography className={classes.subtitle} variant="h6" paragraph>
                  {t("landing.catering.pictures")}
                </Typography>
                <Link to="/catering#pictures" smooth>
                  <Button type="button" className={classes.button}>
                    {t("landing.readMore")}
                  </Button>
                </Link>
              </Grid>
              <Grid className={classes.gridItem} item lg={6} md={5} sm={4}>
                <RestaurantMenuOutlinedIcon className={classes.icon} />
                <Typography className={classes.subtitle} variant="h6" paragraph>
                  {t("landing.catering.pricing")}
                </Typography>
                <Link to="catering/#menu" smooth>
                  <Button type="button" className={classes.button}>
                    {t("landing.readMore")}
                  </Button>
                </Link>
                <br />
                <Button
                  className={classes.button}
                  onClick={() => this.child.current.handleOpen()}
                >
                  {t("contact.contactUs")}
                </Button>
                <ContactUs ref={this.child} />
              </Grid>
              <Grid className={classes.gridItem} item lg={6} md={5} sm={4}>
                <iframe
                  title="fb page"
                  src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fservistorcatering&tabs=timeline&width=${
                    parseInt(
                      window.innerWidth < 500
                        ? window.innerWidth
                        : window.innerWidth / 4
                    ) || 340
                  }&height=${
                    parseInt(
                      window.innerHeight < 900 ? 500 : window.innerHeight / 2
                    ) || 500
                  }&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1559945377690140`}
                  width={
                    (window.innerWidth < 500
                      ? window.innerWidth
                      : window.innerWidth / 4) || 340
                  }
                  height={
                    (window.innerHeight < 900 ? 500 : window.innerHeight / 2) ||
                    500
                  }
                  className={classes.fbPage}
                  scrolling="no"
                  frameborder="0"
                  allowfullscreen="true"
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                ></iframe>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ImagesGrid />
          </Grid>
          <div>
            <Typography className={classes.priaText} variant="h7">
              {t("landing.catering.pria.title")}
            </Typography>
            <img
              className={classes.priaImg}
              src={require(`../assets/img/pria.png`)}
              alt=""
            ></img>
          </div>
          <div>
            <Typography className={classes.priaText} variant="h7">
              {t("landing.catering.eu.title")}
            </Typography>
            <img
              className={classes.euImg}
              src={require(`../assets/img/EL_logo.png`)}
              alt=""
            ></img>
          </div>
        </Grid>

        <Footer
          Tel="+372 53022881"
          Email="aidihall@gmail.com"
          Address="Saare 11, Kaerepere, 79520 Rapla maakond"
          Juridical="Servistor OÜ (11192534)"
          Location="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2446.3930701022205!2d24.838236074591904!3d58.962216593898624!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4692d364d0d6583d%3A0x3b30526cd2c98c3a!2sSaare%2011%2C%20Kaerepere%2C%2079520%20Rapla%20maakond!5e0!3m2!1sen!2see!4v1594573099578!5m2!1sen!2see"
        />
      </div>
    );
  }
}

HomeCatering.propTypes = {
  classes: PropTypes.object.isRequired,
};
HomeCatering = withStyles(styles)(HomeCatering);
export default withTranslation()(HomeCatering);

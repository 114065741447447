import React, { Component } from "react";

import Header from "../components/header";
import CateringInfo from "../components/cateringInfo";
import Footer from "../components/footer";
import Pictures from "../components/pictures";
import Copyright from "../components/copyright";

import { Helmet } from 'react-helmet';
import { withTranslation } from "react-i18next";

class Rentals extends Component {
  render() {
    const { t } = this.props;

    return (
      <div>
        <Helmet>
          <title>{t("catering.title")}</title>
          <meta name="description" content={t("catering.subtitle")} />
        </Helmet>
        <Header />
        <CateringInfo />
        <Pictures />
        <Footer
          Tel="+372 53022881"
          Email="aidihall@gmail.com"
          Address="Saare 11, Kaerepere, 79520 Rapla maakond"
          Juridical="Servistor OÜ (11192534)"
          Location="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2446.3930701022205!2d24.838236074591904!3d58.962216593898624!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4692d364d0d6583d%3A0x3b30526cd2c98c3a!2sSaare%2011%2C%20Kaerepere%2C%2079520%20Rapla%20maakond!5e0!3m2!1sen!2see!4v1594573099578!5m2!1sen!2see"
        />
        <Copyright />
      </div>
    );
  }
}

export default withTranslation()(Rentals);

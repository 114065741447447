import React from "react";
import Typography from "@material-ui/core/Typography";
import FacebookIcon from "@material-ui/icons/Facebook";
import Grid from "@material-ui/core/Grid";

import Link from "@material-ui/core/Link";

const Copyright = () => (
  <div style={{ margin: "4px" }}>
    <Link
      display="block"
      variant="body1"
      href="https://www.facebook.com/servistorcatering/"
      target="_blank"
    >
      <Grid container direction="row" alignItems="center" justify="center">
        <Grid item>
          <FacebookIcon />
        </Grid>
        <Grid item>Servistor catering</Grid>
      </Grid>
    </Link>
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}

      {new Date().getFullYear()}
      {"."}
    </Typography>
  </div>
);
export default Copyright;

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import Header from "../components/header";
import RentInfo from "../components/rentInfo";
import RentToolbar from "../components/rentToolbar";
import RentFooter from "../components/rentFooter";
import Footer from "../components/footer";
import Caravans from "../components/caravans";
import Copyright from "../components/copyright";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { storeMethods } from "../firebase/firestore";

import { setCaravanData } from "../actions";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
// import { useParams } from "react-router-dom";

export default function Rentals(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [_, setRentDates] = React.useState([]);
  const [dateRange, setDateRange] = React.useState(null);

  const handleSetDateRange = (data) => {
    var url = new URL(window.location.href);

    if (data == null) {
      url.searchParams.delete("endDate");
      url.searchParams.delete("startDate");
      window.history.replaceState(null, null, url);
      return
    }
    url.searchParams.set("endDate", data.endDate.toISOString().slice(0, 10));
    url.searchParams.set("startDate", data.startDate.toISOString().slice(0, 10));

    window.history.replaceState(null, null, url);

    setDateRange(data);
  };
  const nullDateRange =()=>{
    setDateRange(null);
  }

  useEffect(() => {
    var url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    if (
      params.has("startDate") &&
      params.has("endDate") &&
      !isNaN(Date.parse(params.get("endDate"))) &&
      !isNaN(Date.parse(params.get("startDate")))
    ) {
      const dates = {
        startDate: new Date(params.get("startDate")),
        endDate: new Date(params.get("endDate")),
      };

      setDateRange(dates);
    }
    storeMethods
      .getAllBookings()
      .then((res) => {
        dispatch(setCaravanData(res));
        setRentDates(res);
      })
      .catch((err) => console.log(err));
  }, [dispatch]);

  return (
    <div>
      <Helmet>
        <title>{t("rent.title")}</title>
        <meta name="description" content={t("rent.subtitle")} />
      </Helmet>
      <Header />
      <RentInfo />
      <div
        id="dates"
        style={{
          background: "#FFFFFF",
          position: "relative",
          zIndex: "3",
          margin: isMobile ? "-60px 4px 0px" : "-60px 30px 0px",
          borderRadius: "6px",
          boxShadow:
            "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
        }}
      >
        <RentToolbar dateRange={dateRange} setDateRange={handleSetDateRange} nullDateRange={nullDateRange}/>
        <Caravans dateRange={dateRange} />
        <RentFooter />
      </div>
      <Footer
        Tel="+372 515 3975"
        Email="hallrauno@gmail.com"
        Address="Rapla, 79513 Rapla maakond"
        Juridical="Servistor Rent OÜ (14737939)"
        Location="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16439.481936650674!2d24.792016006085536!3d58.999549413053536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4692d3b40ad4659d%3A0x83469c3b526bf380!2sRapla%2C%20Rapla%20County!5e0!3m2!1sen!2see!4v1582569095841!5m2!1sen!2see"
      />
      <Copyright />
    </div>
  );
}

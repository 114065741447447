const getUser = () => {
    const localUser = localStorage.getItem("servistorUser")
    return localUser ? JSON.parse(localUser).email : null;
}

const isAuthenticatedReducer = (state = getUser(), action) => {
    switch (action.type) {
        case 'SET_IS_AUTHENTICATED':
            console.log("reducer isAuthenticatedReducer", action)
            return state = action.payload;
        default:
            return state;
    }
}

export default isAuthenticatedReducer;
import firebaseconfig from './firebaseIndex'
import firebase from 'firebase'
import moment from "moment";
import "moment/locale/et";
const db = firebase.firestore();
db.settings({
    timestampsInSnapshots: true
});

export const storeMethods = {
    getBookingsById: async (caravanId) => {
        return new Promise((resolve, reject) => {

            const fTs = firebase.firestore.Timestamp.fromDate(new Date());
            db.collection("caravan_book")
                .where("date", ">=", fTs).where("caravanId", "==", caravanId)
                .get().then(snapshot => {
                    const res = snapshot.docs.map(doc => {
                        const data = doc.data();
                        return { caravanId: data.caravanId, date: moment(data.date.seconds * 1000) }
                    });
                    console.log(res)

                    resolve(res)

                }).catch(err => {
                    console.error(err)
                    reject(err)
                })
        })
    },
    getAllBookings: async () => {
        return new Promise((resolve, reject) => {
            const fTs = firebase.firestore.Timestamp.fromDate(new Date());
            db.collection("caravan_book")
                .where("date", ">=", fTs)
                .get().then(snapshot => {
                    const res = snapshot.docs.map(doc => {
                        const data = doc.data();
                        return { caravanId: data.caravanId, date: moment(data.date.seconds * 1000) }
                    });
                    console.log(res)

                    resolve(res)

                }).catch(err => {
                    console.error(err)
                    reject(err)
                })
        })
    },
    putBooking: (caravanId, date) => {
        return db.collection("caravan_book")
            .add({
                caravanId,
                date: firebase.firestore.Timestamp.fromDate(date)
            })
    },
    deleteBooking: (caravanId, date) => {
        return new Promise((resolve, reject) => {
            console.log("fb date ", firebase.firestore.Timestamp.fromDate(date))
            db.collection("caravan_book")
                .where("caravanId", "==", caravanId)
                .where("date", "==", firebase.firestore.Timestamp.fromDate(date))
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        console.log("delete data ", doc.data())
                        doc.ref.delete().catch(err => reject(err));
                    });
                    console.log("delete finished")
                    resolve()
                })
                .catch(err => reject(err))
        })
    }
}